//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getMonthlyPricing } from '~/utils/affirmHelpers';

export default {
  name: 'Shop_PricingContainer',
  props: {
    pkg: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    financingTotal() {
      return getMonthlyPricing({ productMonths: this.pkg.product.months, payInFullPrice: this.pkg.product.price });
    },
  },
};
